import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

// $('.application-form').on('submit-success', function(event, data) {
//     $('#modal-thank-you').find('.application-uuid').text(data.uuid);
//     $('#modal-thank-you').modal('show');
//
//     const newURL = location.href.split('/')[0] + '/generator';
//     window.history.pushState('object', document.title, newURL);
//
//     $('.form-container').addClass('d-none');
//     $('.fun-container').removeClass('d-none');
// });

$('#modal-thank-you').on('hide.bs.modal', function () {
    window.location.href = '/generator';
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[street]"]',
        '[name="personal_data[streetNr]"]',
        '[name="personal_data[flatNr]"]',
        '[name="personal_data[zipCode]"]',
        '[name="personal_data[city]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    $('#modal-winner-thank-you').modal('show');

    $('.form-container').remove();
    $('.after-fill').removeClass('d-none');
});

$('.contact-form').on('submit-success', function(event, data) {
    setRegisteredData(data, 'section#contact');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-contact').modal('show');
});

$('.login-form').on('submit-success', function(event, data) {
    $('#modal-login').modal('hide');
    $('#nav-login-button').remove();
    $('#navigation-dropdown').find('.navbar-nav').append('<a href="/profile" class="btn btn-secondary">Moje konto</a>');

    $('#generator').attr('data-token', data.token);

    $('#create-account').remove();
    $('.create-account-switch').remove();
    $('.create-account-section').remove();

    if ($(this).attr('data-open') === 'save') {
        $(this).removeAttr('data-open');
        $('#save-package').trigger('click');
    }
});

$('.change-password-form').on('submit-success', function(event, data) {
    $('#modal-change-password').find('form').addClass('d-none');
    $('#modal-change-password').find('.after').removeClass('d-none');
});

$('#modal-change-password').on('hide.bs.modal', function () {
    $('#modal-change-password').find('form').removeClass('d-none');
    $('#modal-change-password').find('.after').addClass('d-none');
});
