import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

let sProductsSlider;

sProductsSlider = new Swiper($('.s-products__slider .swiper')[0], {
    slidesPerView: 1,
    loop: true,
    touchRatio: 0.1,
    centeredSlides: true,
    breakpoints: {
        992: {
            slidesPerView: 3,
        }
    },
    navigation: {
        prevEl: $('.s-products__slider .swiper').siblings('.swiper-button-prev')[0],
        nextEl: $('.s-products__slider .swiper').siblings('.swiper-button-next')[0]
    },
    modules: [Navigation]
});

let sStagesSlider;

sStagesSlider = new Swiper($('.s-winners__slider .swiper')[0], {
    slidesPerView: 2,
    loop: false,
    touchRatio: 0.1,
    breakpoints: {
        768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
        }
    },
    pagination: {
        el: $('.s-winners__slider .swiper').siblings('.swiper-pagination')[0],
        clickable: true
    },
    navigation: {
        prevEl: $('.s-winners__slider .swiper').siblings('.swiper-button-prev')[0],
        nextEl: $('.s-winners__slider .swiper').siblings('.swiper-button-next')[0]
    },
    modules: [Navigation, Pagination],
    on: {
        click() {
            console.log('index', this.clickedIndex);
            sStagesSlider.slideTo(this.clickedIndex);
            $('.s-winners__slider .swiper').find('.swiper-slide button').removeClass('active');
            $($('.s-winners__slider .swiper').find('.swiper-slide')[this.clickedIndex]).find('button').addClass('active');

            const prize = $('.s-winners__prizes button.active').attr('data-target');
            const week = $($('.s-winners__slider .swiper').find('.swiper-slide')[this.clickedIndex]).attr('data-target');
            

            console.log(prize, week)

            $(prize).find('.s-winners__content-1').addClass('d-none');
            $(prize).find(week).removeClass('d-none');
        },
    },
});

 sStagesSlider.on('slideChange', function (e) {
     console.log(e, $(this));
    // $('.s-winners__slider .swiper').find('.swiper-slide button').removeClass('active');
    //
    // $('.s-winners__slider .swiper').find('.swiper-slide').find('button').addClass('active');
 });

// sStagesSlider.on('click', function (swiper, e) {
//     $('.s-winners__slider .swiper').find('.swiper-slide button').removeClass('active');
//
//     $($('.s-winners__slider .swiper').find('.swiper-slide')[swiper.clickedIndex]).find('button').addClass('active');
//
//     const prize = $('.s-winners__prizes button.active').attr('data-target');
//     const week = $(this).attr('data-target');
//
//     $(prize).find('.s-winners__content-1').addClass('d-none');
//     $(prize).find(week).removeClass('d-none');
// });
